<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {dateUtil} from "@/helpers/date-util";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";


/**
 * Logs Component
 */
export default {
  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },
  components: {

    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,

        items: []
      },

      inputPage: "",

      users: null
    };
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('logs.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "user", slot: true, label: this.$t('logs.table.user')},
        {key: "activity", label: this.$t('logs.table.activity')},
        {key: "type", slot: true, label: this.$t('logs.table.log-type')},
        {key: "createdAt", label: this.$t('logs.table.created-at'), formatter: value => dateUtil.asDateTime(value)}
      ]
    },

    async loadLogs() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const result = await axios.get(`/log/list/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "page": page,
            "size": this.table.perPage
          }
        });

        this.table.items = result.data
        await this.loadLogsCount()
        await this.loadUsers()

        return this.table.items;
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadLogsCount() {
      try {
        const result = await axios.get(`/log/count`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.table.totalRows = result.data;
        this.table.rows = result.data;
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadUsers() {
      try {
        if (this.table.items.length === 0) {
          return;
        }

        const ids = [];
        for (let log of this.table.items) {
          ids.push(log.userId);
        }

        const json = JSON.stringify({
          "ids": ids
        });

        const result = await axios.post(`/user`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.users = new Map(result.data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getUserEmail(userId) {
      if (!this.users) {
        return {}
      }

      let user = this.users.get(userId);
      return user.email;
    },

  },

  async created() {
    try {
      await this.loadLogs()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('logs.title')" :items="getItems()"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <ecat-table
                ref="table"
                :table="table"
                :fields="getFields()"
                :items="loadLogs"
                :pagination-top="true"

            >
              <template v-slot:user="{ item }">
                <span>{{ getUserEmail(item.userId) }}</span>
              </template>

              <template v-slot:type="{ item }">
                <span> {{ $t('logs.types.' + item.type) }}</span>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>